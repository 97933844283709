import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import { createRouter, createWebHashHistory } from "vue-router"
import 'element-plus/dist/index.css'
import myHome from './components/myHome.vue'
import myDetail from './components/myDetail.vue'
import myCreate from './components/myCreate.vue'
import App from './App.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: myHome,
	meta: {
      title: '首页',
      keepAlive: true // 不需要缓存
    }
  },
  {
    path: '/detail',
    name: 'detail',
    component: myDetail
  },
  {
    path: '/create',
    name: 'create',
    component: myCreate
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.mount('#app')
