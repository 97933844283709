<template>
  <div class="media-detail">
    <el-col :span="24" :md="12" :lg="8" v-for="(item, index) in mediaList" :key="index">
          <!-- 媒体项代码略 -->
          <video controls muted loop v-if="item.list_type==2">
          <source :src="'https://one.iccat.cn/prod' + item.list_path.slice(7)" type="video/mp4"/>
          </video>
          <div v-if="item.list_type==3">
          <div v-for="bi in JSON.parse(item.list_path)" :key="bi">
          <video controls muted loop>
          <source :src="'https://one.iccat.cn/prod' + bi.slice(7)" type="video/mp4"/>
          </video>
          <p></p>
          </div>
          </div>
          <div class="demo-image__lazy" v-if="item.list_type==5">
           <el-image v-for="ite in JSON.parse(item.list_path).img" :key="ite" :src="'https://one.iccat.cn/prod/douyin/images/' + JSON.parse(item.list_path).aweme_id + '/' + (ite -1) + '.webp'" lazy />
          </div>
          <p></p>
          <p>{{ item.list_disc }}</p>
          <p>作者： {{ item.list_user }}</p>
          <audio controls muted loop v-if="item.list_type==5"> 
          <source :src="'https://one.iccat.cn/prod/douyin/music/' + JSON.parse(item.list_path).aweme_id + '.mp3'" type="audio/ogg" />
          </audio>
          <el-form-item>
            <el-button type="primary" @click="navigateToHmoe()">返回</el-button>
          </el-form-item>
          <div class="media-info">
            <h3 class="media-title">{{ item.list_name.slice(1,50) }}</h3>
            <p class="media-date">{{ item.list_date }}    </p>
          </div>
    </el-col>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
export default {
  setup() {
    const route = useRoute();
    console.log(route.query.list_id);
    const mediaList = ref([])
    const getMediaList = () => {
      axios.get('https://one.iccat.cn/prod/media/down/lists?list_statu=5', {
        params: {
          list_id: route.query.list_id
        }
      }).then(response => {
        mediaList.value = response.data.data.items
      })
    }
    
    onMounted(() => {
      getMediaList()
    })
    
    return {
      mediaList,
      getMediaList
      }
  },
  methods: {
    navigateToHmoe() {
      // 导航到详细内容页，传递媒体项的详细信息作为参数
      this.$router.go(-1);
    }
    }
}
</script>

<style scoped>
/* 样式可以根据需求进行调整 */
.media-detail {
  padding: 20px;
}
video {
  margin: 5px auto;
  width: 100%;
}
.demo-image__lazy {
  height: 800px;
  overflow-y: auto;
}
.demo-image__lazy .el-image {
  display: block;
  min-height: 200px;
  margin-bottom: 10px;
}
.demo-image__lazy .el-image:last-child {
  margin-bottom: 0;
}
</style>