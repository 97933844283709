<template>
  <el-form @submit.prevent="onSubmit">
    
    <el-form-item label="视频链接">
      <el-input v-model="list_url" placeholder="请输入视频链接"></el-input>
    </el-form-item>

    <el-form-item label="模式">
      <el-select v-model="list_type" clearable placeholder="请选择">
        <el-option label="抖音视频" value="2"></el-option>
        <el-option label="抖音图片" value="5"></el-option>
        <el-option label="B站视频" value="3"></el-option>
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit">立即提交</el-button>
      <el-button @click="onReset">重置</el-button>
    </el-form-item>
    
    <el-form-item>
          <el-button type="primary" @click="navigateHome()">主页</el-button>
    </el-form-item>
  </el-form>
  
  <div class="media-detail" v-if="mediaList.code">
    <el-col :span="24" :md="12" :lg="8" v-if="mediaList.data.code == 200">
        <!-- 媒体项代码略 -->
        <p v-if="mediaList.data.list_type==2">
        <a :href="mediaList.data.list_path"> 视频链接 </a>
        </p>
        
        <div class="demo-image__lazy" v-if="mediaList.data.list_type==5">
           <el-image v-for="ite in mediaList.data.list_images" :key="ite" :src="ite.url_list[0]" lazy />
        </div>
        
        <p></p>
        <p>{{ mediaList.data.list_disc }}</p>
        <p>作者： {{ mediaList.data.list_user }}</p>
        <audio controls muted loop> 
        <source :src="mediaList.data.list_music" type="audio/ogg" />
        </audio>
        <div class="media-info">
          <h3 class="media-title">{{ mediaList.data.list_name.slice(1,50) }}</h3>
          <p class="media-date">{{ mediaList.data.list_date }}    </p>
        </div>
    </el-col>
    <p>状态： {{ mediaList.data.remark }}</p>
  </div>
</template>

<script>
import { ref, reactive, toRefs } from 'vue';
import { ElMessage } from 'element-plus';
import axios from 'axios';

export default {
  setup() {
    const form = reactive({
      list_url:'',
      list_type:'2'
    });
    
    let url = 'https://one.iccat.cn/prod/media/down/jiexi';
    
    const mediaList = ref([])
    
    const onSubmit = async () => {
      try {
        ElMessage.success('下载已开始!');
        const response = await axios.post(url, form,{
         headers: {
           'Content-Type': 'application/json'
         }
        });
        ElMessage.success(response.data);
        mediaList.value = response.data
      } catch (error) {
        ElMessage.error('下载失败: ' + error.message);
        // mediaList.value = error.message
      }
    };

    const onReset = () => {
      Object.assign(form, {
        list_url:'',
        list_type:'2'
      });
    };

    return {
      ...toRefs(form),
      onSubmit,
      onReset,
      mediaList
    };
  },
  
  methods: {
    navigateHome() {
      // 导航到详细内容页，传递媒体项的详细信息作为参数
      this.$router.push({ name: 'home' });
    },
    }
};
</script>

<style>
/* 这里可以添加CSS样式 */
.demo-image__lazy {
  height: 800px;
  overflow-y: auto;
}
.demo-image__lazy .el-image {
  display: block;
  min-height: 200px;
  margin-bottom: 10px;
}
.demo-image__lazy .el-image:last-child {
  margin-bottom: 0;
}
</style>