<template>
  <div class="media-list">
    <div class="media-search">
      <!-- 搜索表单代码略 -->
      <el-form :inline="true" :model="searchForm" class="media-search-form">
        <el-form-item label="类型">
          <el-select v-model="searchForm.media_type" clearable placeholder="请选择">
            <el-option label="抖音视频" value="2"></el-option>
            <el-option label="抖音图片" value="5"></el-option>
            <el-option label="B站视频" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="作者">
          <el-select v-model="searchForm.media_info" clearable placeholder="请选择">
            <el-option v-for="item in mediaInfoList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="searchForm.media_content" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="navigateCreate()">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="media-items">
      <el-row :gutter="20">
        <el-col :span="24" :md="12" :lg="8" v-for="(item, index) in mediaList" :key="index">
          <!-- 媒体项代码略 -->
          <div class="media-item" @click="navigateToDetail(item)">
          <img :src="'https://one.iccat.cn/prod' + item.list_avatar.slice(7)" alt="" class="media-photo">
          <div class="media-info">
            <h3 class="media-title">{{ item.list_name.slice(1,50) }}</h3>
            <p class="media-date">{{ item.list_date }}</p>
          </div>
        </div>
        </el-col>
      </el-row>
    </div>
    <div class="media-separator"></div>
    <div class="media-pagination">
      <!-- 分页组件代码略 -->
      <el-pagination
        v-model="currentPage"
        :page-size="pageSize"
        :total="total"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <div class="media-separator"></div>
    <div class="media-separator"></div>
    <div class="media-separator"></div>
    <div class="media-separator"></div>
    <div class="media-footer">
      <!-- 版权声明、日期、备案等信息代码略 -->
      <p>版权所有 © 2023 - {{ getCurrentDate()[0] }} 屹铭    {{ getCurrentDate()[0] }}年{{ getCurrentDate()[1] }}月{{ getCurrentDate()[2] }}日   备案号：XXXXXXX</p>
    </div>
  </div>
</template>


<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'

export default {
  setup() {
    const mediaList = ref([])
    const currentPage = ref(1)
    const pageSize = ref(12)
    const total = ref(0)
    const searchForm = ref({
      media_info: '',
      media_content: '',
      media_type:''
    })
    const mediaInfoList = ref([])

    const getMediaList = () => {
      axios.get('https://one.iccat.cn/prod/media/down/lists?list_statu=5', {
        params: {
          page: currentPage.value,
          pagesize: pageSize.value,
          list_user: searchForm.value.media_info,
          list_name: searchForm.value.media_content,
          list_type: searchForm.value.media_type
        }
      }).then(response => {
        mediaList.value = response.data.data.items
        total.value = response.data.data.pageInfo.total
      })
    }

    const handleCurrentChange = (currPage) => {
      console.log(currPage)
      currentPage.value = currPage
      getMediaList()
    }

    const getMediaInfoList = () => {
      axios.get('https://one.iccat.cn/prod/media/down/user?list_statu=5').then(response => {
        mediaInfoList.value = response.data.data
      })
    }

    const handleSearch = () => {
      currentPage.value = 1
      getMediaList()
    }
    
    const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return [year, month, day];
    }

    onMounted(() => {
      getMediaList()
      getMediaInfoList()
    })

    return {
      mediaList,
      currentPage,
      pageSize,
      total,
      searchForm,
      mediaInfoList,
      getMediaList,
      handleCurrentChange,
      getMediaInfoList,
      handleSearch,
      getCurrentDate
      }
  },
  
  methods: {
    navigateToDetail(item) {
      // 导航到详细内容页，传递媒体项的详细信息作为参数
      this.$router.push({ name: 'detail', query: { list_id: item.list_id, } });
    },
    navigateCreate() {
      // 导航到详细内容页，传递媒体项的详细信息作为参数
      this.$router.push({ name: 'create' });
    },
    }
}
</script>

<style>
.media-list {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.media-item {
  position: relative;
  overflow: hidden;
  flex-basis: 23%;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .media-item {
    flex-basis: 100%;
  }
}

.media-photo {
  display: block;
  width: 100%;
  height: auto;
}

.media-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

.media-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.media-date {
  margin: 0;
  font-size: 14px;
}

.media-items {
  margin-bottom: 0px;
  width: 100%;
}

.media-separator {
  margin: 5px auto;
  height: 5px;
  width: 100%;
}

.media-pagination {
  margin: 0 auto;
  text-align: center;
}

.media-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0px; /* 可选，设置内边距 */
  background-color: #f0f0f0;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.media-search {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.media-search-form {
  display: inline-block;
}
</style>